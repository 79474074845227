<template>
  <section fluid>
    <p class="text-h6 font-museo-sans secondary--text" id="titulo">
      Perfil de proveedor - Configuración de OBS
    </p>
    <div class="text-h6">
      <p class="grayMinsal--text mb-n1">Configuración de OBS</p>
      {{ obsToConfig?.obs_codigo }} - {{ obsToConfig?.obs_nombre }}
    </div>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-text-field
          label="Título *"
          outlined
          style="width: 500px"
          color="secondary"
          v-model="obsToConfig.titulo"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          outlined
          label="Descripción *"
          style="width: 500px"
          color="secondary"
          v-model="obsToConfig.descripcion"
        ></v-textarea>
        <v-col>
          <div class="d-flex align-center flex-wrap" style="gap: 16px; width: 500px;">
            <v-btn 
              @click="$router.back()" 
              class="flex-grow-1 flex-shrink-1"
              color="secondary" 
              outlined 
              large
            >
              Regresar
            </v-btn>
            <v-btn 
              @click="actualizarOBS()" 
              class="flex-grow-1 flex-shrink-1"
              color="primary" 
              dark 
              large
            >
              Actualizar datos de OBS
            </v-btn>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "configuracionOBSView",
  data() {
    return {
      title: null,
      description: null,
    };
  },
  computed: {
    ...mapState("proveedores", ["obsToConfig"]),
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async actualizarOBS() {
      let id = this.$route.params.id
      let obs = {
        titulo : this.obsToConfig.titulo,
        descripcion: this.obsToConfig.descripcion
      }

      const response = await this.services.Proveedores.putProviderOBS(id, obs);

      if(response?.status === 200){
        this.temporalAlert({
          show:true,
          message:"Se actualizó con éxito",
          type: "success"
        })
        
      }
    }
  },
};
</script>
